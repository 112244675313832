import { useSession } from '@/components/context/SessionContext';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Link } from '@tanstack/react-router';
import { useContext } from 'react';
import { ThemeSwitcher } from './ThemeSwitcher';
import { DebugContext } from './context/DebugContext';

export default function NavBar() {
  const { user, logout } = useSession();
  const isLoggedIn = !!user; //!!session;

  const { debug } = useContext(DebugContext);
  if (debug) {
    console.debug('dev mode');
  }
  // const { session, user } = useContext(SessionContext);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    window.location.href = '/login';
    // await navigate({ to: '/login' });
  };

  return (
    // <LoggedInContext.Consumer>
    // {({ isLoggedIn }) => {
    // console.log('navbar logged in: ', isLoggedIn);
    // return (
    <nav
      key={isLoggedIn ? 1 : 0}
      className='box-border flex w-full flex-row justify-between border-b px-4 py-2 dark:border-zinc-900'
    >
      <div className=''>
        <ThemeSwitcher />
      </div>
      <div className={'my-auto font-semibold text-xl'}>
        <Link to={'/'}>
          <span className={'tracking-tight dark:text-zinc-300 '}>
            Fli<span className={'px-[0.05rem] tracking-[-.1em]'}>xx</span>r
          </span>
        </Link>
        {/*<span className={'text-red-800'}>Flix</span>*/}
      </div>
      {/*{debug && (*/}
      {/*  <div className='navbar-center w-96 dark:text-white'>*/}
      {/*    session: {JSON.stringify(session)} <br /> user:{JSON.stringify(user)}*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className='navbar-end float-right flex flex-row'>
        {!isLoggedIn && (
          <Button variant={'ghost'} className='' asChild>
            <Link to={'/signup'}>Signup</Link>
          </Button>
        )}

        {!isLoggedIn && (
          <Button variant={'ghost'} className=''>
            <Link to={'/login'}>Login</Link>
          </Button>
        )}

        {isLoggedIn && (
          <DropdownMenu>
            {/* // onClick={handleMenu}
            className='p-2'

            // component={RouterLink}
            // href='/auth/profile'
            // variant='contained'
          > */}
            <DropdownMenuTrigger className={'mr-4'}>
              <Avatar>
                <AvatarImage src='https://github.com/shadcn.png' alt='@shadcn' />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className={'mt-4 mr-6'}>
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />

              <DropdownMenuItem asChild>
                <Link activeOptions={{ exact: true }} to='/account'>
                  Account
                </Link>
              </DropdownMenuItem>

              <DropdownMenuItem asChild>
                <Link to='/account/settings'>Settings</Link>
              </DropdownMenuItem>

              <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </nav>
    //     );
    //   }}
    // </LoggedInContext.Consumer>
  );
}
