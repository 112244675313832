'use client';

import { CreateGroupCard } from '@/routes/-CreateGroupCard';
import { Navigate, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/')({ component: Home });
export default function Home() {
  return (
    <>
      <Navigate to={'/login'} />
      <div className='mt-4 flex flex-col items-center '>
        {/* <CreateGroupCard />
         */}
        Homepage
        <br />
        Version: {import.meta.env.VITE_GIT_REF_NAME ?? 'dev'}
      </div>
    </>
  );
}
