// import { useEffect } from 'react';

import { useSession } from '@/components/context/SessionContext';
import { Card } from '@/components/ui/card';
import { Navigate, Outlet, createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { GithubLoginButton } from 'react-social-login-buttons';

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context }: { context: { user; session } }) => {
    const { user } = context;
    if (user) {
      throw redirect({ to: '/home' });
    }
  },
  component: Layout
});

export default function Layout() {
  // const cookieStore = cookies();
  const { user } = useSession();
  // const navigate = useNavigate();
  if (user) {
    return <Navigate to='/home' />;
  }
  return (
    <div className='mx-auto w-11/12 lg:w-1/3 lg:max-w-7xl'>
      <Card className=' card card-bordered mt-10 p-4 shadow-lg'>
        <Outlet />
        <GithubLoginButton
          onClick={() => {
            const url = new URL('/auth/login/github', import.meta.env.VITE_API_URL);
            window.location.href = url.toString();
          }}
        />
      </Card>
    </div>
  );
}
