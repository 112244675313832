'use client';
import { Link, useLocation } from '@tanstack/react-router';
// import Link from 'next/link';
// import { usePathname, useRouter } from 'next/navigation';

export default function Layout() {
  // const pathname = usePathname();
  const l = useLocation();
  const pathname = l.pathname;
  const page = pathname.split('/')[3];

  return (
    <div role='tablist' className='tabs-boxed tabs'>
      <Link
        to='/account/settings/general'
        role='tab'
        className={`tab ${page === 'general' ? 'tab-active' : ''}`}
      >
        General
      </Link>
    </div>
  );
}
