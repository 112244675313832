import { paths } from '@repo/apitypes';
import * as sentry from '@sentry/react';
import ky from 'ky';
import { createContext, useContext, useEffect, useState } from 'react';

const url = import.meta.env.VITE_API_URL;

export const baseapi = ky.extend({
  prefixUrl: url,
  credentials: 'include',
  hooks: {
    beforeError: [
      async (error) => {
        sentry.captureException(error);
        return error;
      }
    ]
  }
});
export const ApiContext = createContext({
  api: baseapi,
  setCsrfToken: (_token: string) => {
    return;
  }
});

export const ApiProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  useEffect(() => {
    baseapi.get('csrf').then(async (res) => {
      const { csrfToken }: { csrfToken: string } = await res.json();
      setCsrfToken(csrfToken);
    });
  }, []);
  const api = baseapi.extend({
    hooks: {
      beforeRequest: [
        async (request) => {
          if (csrfToken != null) {
            request.headers.set('CSRF-TOKEN', csrfToken);
          }
          return request;
        }
      ]

      // afterResponse: [
      //   async (_request, _options, response) => {
      //     if (response.headers.has('set-xsrf-token')) {
      //       console.log('CSRF TOKEN');
      //       console.log(response.headers.get('set-xsrf-token'));
      //       const csrfToken = response.headers.get('set-xsrf-token');
      //       if (csrfToken != null) {
      //         setCsrfToken(csrfToken);
      //       }
      //     }
      //     return response;
      //   }
      // ]
    }
  });
  return <ApiContext.Provider value={{ api, setCsrfToken }}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
