// import Link from 'next/link';

import { useSession } from '@/components/context/SessionContext';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Link, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/account/')({ component: AccountPage });
export default function AccountPage() {
  // return {
  //   redirect: {
  //     destination: '/auth/login',
  //     permanent: false,
  //   },
  // };
  const { user } = useSession();
  return (
    <div className={'mt-8 flex flex-col items-center px-4'}>
      <Card
        className={'card w-full bg-base-200 shadow-lg sm:w-10/12 md:w-8/12 lg:w-7/12 2xl:w-5/12'}
      >
        <CardContent className={'card-body flex flex-col items-center'}>
          <Avatar className={'mt-8 h-20 w-20'}>
            <AvatarImage src='https://github.com/shadcn.png' alt='@shadcn' />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
          <div className={'flex w-full flex-col items-start'}>
            <span className='font-bold text-2xl'>{user?.username}</span>
            <span className='font-medium text-xl'>{user?.email}</span>
          </div>

          <div className='flex w-full flex-col'>
            <h3>Linked Accounts:</h3>

            <ul>
              {user?.linkedAccounts.map((account) => (
                <li key='account'>
                  {account.replace(
                    /\w\S*/g,
                    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className='card-actions'>
            <Link to='/account/edit'>
              <Button className=''>Edit</Button>
            </Link>
          </div>

          <Separator className={'my-2'} />
          <div>
            <h3>Friends</h3>
            <p>You have no friends :(</p>
            <p className={'text-xs'}>lol</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
