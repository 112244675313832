import { useApi } from '@/components/context/ApiContext';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import type { paths } from '@repo/apitypes';
import { useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  groupname: z
    .string()
    .min(3)
    .max(50)
    .refine((value) => value !== 'abcd', {
      message: 'Group name cannot be abcd'
    })
});

type TCreateGroupResponse =
  paths['/party']['post']['responses']['200']['content']['application/json'];
type TCreateGroupRequest = paths['/party']['post']['requestBody']['content']['application/json'];

export const CreateGroupCard = () => {
  const { api } = useApi();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      groupname: ''
    }
  });
  const navigate = useNavigate();
  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    // console.log('data', data);
    const json: TCreateGroupRequest = { name: data.groupname };

    const res = await api.post<TCreateGroupResponse>('party', { json }).json();

    const { id } = res.party;

    navigate({ to: `/party/${id}` });

    // form.setError('groupname', { message: 'This is an error message' });
  };

  return (
    <Card className={'min-w-96'}>
      <CardHeader>
        <CardTitle>Create Group</CardTitle>
        <CardDescription>Create movie finder group</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className={''}>
            <FormField
              control={form.control}
              name={'groupname'}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {' '}
                    Group Name
                    <FormControl>
                      <Input placeholder='Movie Gang!' {...field} />
                    </FormControl>
                  </FormLabel>
                  <FormDescription>This is the name of your party</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className={'mt-4'}>Create Group</Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
